/* global $ */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { decodePassengers } from 'utils/Reserbus';
import {
  productListTracker,
  resultsActionsTracker,
  sortTracker,
  filterTracker,
} from 'metrics/user-analytics/search';
import pickedProviderTracker from 'metrics/user-analytics/pickedProviderTracker';
import { selectedTripTracker } from 'metrics/user-analytics/trip';
import LoadingScreen from 'components/LoadingScreen';
import TripListHeader from 'components/TripListHeader';
import TripList from 'components/search/TripList';
import TripDetails from 'components/TripDetails';
import OverlayView from 'components/OverlayView';
import { Spacing, Text, Visibility } from '@reservamos/elements';
import ModalDiscount from 'components/search/ModalContent/ModalDiscount';
import ModalOpenTicket from 'components/search/ModalContent/ModalOpenTicket';
import { getMomentFromSearchDateParam } from 'utils/search/searchDateParam';
import DiscountBanner from 'ui/atoms/DiscountBanner';
import SwitchDoters from 'components/search/SwitchDoters/SwitchDoters';
import withGrowthBookFeatures from 'components/GrowthBookProvider/withGrowthBookFeatures';
import LineBanner from 'ui/atoms/LineBanner';
import { When } from 'react-if';
import RouteTerminalsDisplay from 'ui/molecules/RouteTerminalsDisplay';
import { getRecommendedRedirectPayload, trackRecommendedTripSelected } from 'utils/userPreferences';
import { getSeatsPurchasePayload, isSeatsOnResultActivated } from 'utils/seats';
import NoResults from '../../../ui/molecules/NoResults';
import SearchSidebar from '../../SearchSidebar';
import LabelLoading from '../../../ui/molecules/LabelLoading';
import NormalTicketWrapper from '../NormalTicketWrapper';
import NormalTicketButton from '../NormalTicketButton';
import Coupon from '../SearchCoupon/SearchCoupon';
import OpenTicketList from '../OpenTicketList';
import ChangeProvider from '../../../ui/atoms/ChangeProvider';
import DayControls from '../DayControls';
import WhiteBar from '../../../ui/atoms/WhiteBar';
import NerbyTerminals from '../NerbyTerminals';
import ResultsTitle from '../../../ui/atoms/ResultsTitle';
import { cleanAnalyticsParams } from '../../../utils/urls';
import RecommendedTripsList from '../RecommendedTrips';
import OpenTicketCTA from '../OpenTicketCTA';

const propTypes = {
  origin: PropTypes.object.isRequired,
  destination: PropTypes.object.isRequired,
  passengers: PropTypes.string.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  departureDate: PropTypes.string.isRequired,
  returnDate: PropTypes.string,
  providers: PropTypes.array,
  provider: PropTypes.object,
  transportType: PropTypes.string,
  searchIsPolling: PropTypes.bool.isRequired,
  paymentPlans: PropTypes.objectOf(PropTypes.number).isRequired,
  installmentsMinAmount: PropTypes.number.isRequired,
  trips: PropTypes.array.isRequired,
  noTrips: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortProvidersBy: PropTypes.string,
  departureFilter: PropTypes.string.isRequired,
  stopsFilter: PropTypes.string.isRequired,
  locations: PropTypes.object.isRequired,
  departureLocationFilter: PropTypes.string.isRequired,
  arrivalLocationFilter: PropTypes.string.isRequired,
  activeDepartureFilter: PropTypes.bool.isRequired,
  activeLocationFilter: PropTypes.bool.isRequired,
  activeStopsFilter: PropTypes.bool.isRequired,
  tooManyFilters: PropTypes.bool.isRequired,
  currentPath: PropTypes.string.isRequired,
  providerName: PropTypes.string,
  adAttribution: PropTypes.string,
  showModal: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  setTripFilter: PropTypes.func.isRequired,
  resetTripFilters: PropTypes.func.isRequired,
  transitionTo: PropTypes.func.isRequired,
  replaceUrl: PropTypes.func.isRequired,
  closeTripDetails: PropTypes.func.isRequired,
  tripDetails: PropTypes.object,
  openTrip: PropTypes.object,
  isStopsFilterAvailable: PropTypes.bool,
  categoriesFilter: PropTypes.array,
  tripOptionsFilter: PropTypes.array,
  t: PropTypes.func.isRequired,
  couponCode: PropTypes.string,
  discountedTripsByCoupon: PropTypes.array,
  setQueryParams: PropTypes.func,
  isOpenTicketList: PropTypes.bool,
  openTicketService: PropTypes.string,
  setRedirectParams: PropTypes.func,
  hasDynamicPromotion: PropTypes.bool.isRequired,
  isLogged: PropTypes.bool,
  nearTerminals: PropTypes.array,
  providerId: PropTypes.string,
  areOpenTicketTrips: PropTypes.bool,
  features: PropTypes.object.isRequired,
  setTripFilters: PropTypes.func,
  filters: PropTypes.object,
  tripsWithoutFilters: PropTypes.array,
  discountPercent: PropTypes.number,
  growthBookFeatures: PropTypes.object.isRequired,
  operationNumbers: PropTypes.array,
  nit: PropTypes.string,
  goBack: PropTypes.func,
  resultPriceToShow: PropTypes.bool,
  firstTripLineId: PropTypes.string,
  firstTripLineLogo: PropTypes.string,
  brand: PropTypes.string,
  passengersNotFound: PropTypes.array,
  tripTerminalInfo: PropTypes.object,
  passengersQueryParam: PropTypes.string,
  resetSeatBus: PropTypes.func,
  fetchTripsDetails: PropTypes.func,
  newPurchase: PropTypes.func,
};

/**
 * Represents the ProviderDepartures component.
 */
class ProviderDepartures extends Component {
  /**
   * Constructs a new ProviderDepartures component.
   * @param {object} props - The component props.
   */
  constructor(props) {
    super(props);

    this.goToHome = this.goToHome.bind(this);
    this.optionsClick = this.optionsClick.bind(this);
    this.setSortOrder = this.setSortOrder.bind(this);
    this.setActiveFilter = this.setActiveFilter.bind(this);
    this.selectTrip = this.selectTrip.bind(this);
    this.selectTripDefault = this.selectTripDefault.bind(this);
    this.goToProviders = this.goToProviders.bind(this);
    this.selectProvider = this.selectProvider.bind(this);
    this.selectOpenTripList = this.selectOpenTripList.bind(this);
    this.backToNormalTicket = this.backToNormalTicket.bind(this);
    this.selectTripRedirect = this.selectTripRedirect.bind(this);
    this.redirectRoundTrip = this.redirectRoundTrip.bind(this);
    this.setActiveFilters = this.setActiveFilters.bind(this);
  }

  componentDidMount() {
    const { trips, resetSeatBus } = this.props;
    $(window).scrollTop(0);
    /*
      FIXME correct the scroll issue with the address bar
    */
    setTimeout(() => $(window).scrollTop(0), 300);

    if (trips.length) {
      this.trackProductList(this.props);
    }
    cleanAnalyticsParams();
    resetSeatBus();
  }

  componentDidUpdate(prevProps) {
    const { provider, trips } = this.props;
    if (provider && prevProps.trips.length !== trips.length) {
      this.trackProductList();
    }
  }

  componentWillUnmount() {
    const { resetTripFilters, features } = this.props;
    if (!features.NEW_FILTERS_VERSION) resetTripFilters();
  }

  getAnalyticsData() {
    const { origin, destination, roundTrip, departureDate, returnDate } = this.props;

    return {
      origin,
      destination,
      roundTrip,
      departureDate: moment(departureDate, 'DD-MMM-YY').toDate(),
      returnDate: moment(returnDate, 'DD-MMM-YY').toDate(),
    };
  }

  setSortOrder(sortBy, list) {
    const { setSortOrder } = this.props;

    setSortOrder(sortBy, list);
    sortTracker(this.getAnalyticsData(), sortBy, 'Departures');
  }

  setActiveFilter(filterBy, active) {
    const { setTripFilter } = this.props;

    setTripFilter(filterBy, active);
    filterTracker(this.getAnalyticsData(), active, 'Departures');
  }

  /**
   * Applied several filters to the search
   * @param {Object} filters - Filters applied
   * @param {*} isFiltersUpdating - Indicates if it is an update of filters but not made by the user
   */
  setActiveFilters(filters, isFiltersUpdating) {
    const { setTripFilters } = this.props;
    setTripFilters(filters);
    if (!isFiltersUpdating) filterTracker(this.getAnalyticsData(), filters, 'Departures');
  }

  backToNormalTicket() {
    const { currentPath, transitionTo } = this.props;
    const route = currentPath.split('/');

    route.pop();

    transitionTo(route.join('/'));
  }

  trackProductList() {
    const { transportType, trips, provider, passengers } = this.props;

    // Check if provider is undefined
    if (provider === undefined) {

      return; // Optionally, return or handle the case where provider is undefined
    }

    const { name } = provider; // This is now safe because you've checked that provider is not undefined

    productListTracker(transportType, trips, name, decodePassengers(passengers), 'departure');
  }

  goToHome() {
    const { transitionTo } = this.props;
    transitionTo('/');
  }

  optionsClick(option, modalComponent) {
    const { showModal } = this.props;

    showModal(modalComponent);
    resultsActionsTracker(this.getAnalyticsData(), option, 'Departures');
  }

  redirectRoundTrip(trip) {
    const {
      origin,
      destination,
      departureDate,
      returnDate,
      provider,
      passengers,
      transitionTo,
      features,
    } = this.props;

    let route =
      `/search/${origin.id}/${destination.id}/${departureDate}/${returnDate}` +
      `/p/${passengers}/${trip.id}/return/providers`;
    if (!features.PROVIDERS_SELECTION_ENABLED) {
      route =
        `/search/${origin.id}/${destination.id}/${departureDate}/${returnDate}` +
        `/p/${passengers}/${trip.id}/returns/${provider.id}`;
    }
    const recommendedPayload = getRecommendedRedirectPayload({ trip });
    transitionTo(route, {
      seenPrice: trip.pricing.total,
      departs: trip.id,
      ...(recommendedPayload && { tripsInfo: recommendedPayload }),
    });
  }

  selectTripDefault(trip, position, isOpenTicket = false) {
    const { roundTrip, passengers, transitionTo, operationNumbers, nit } = this.props;
    selectedTripTracker(trip, position, 'departure', isOpenTicket);
    if (roundTrip) {
      this.redirectRoundTrip(trip);
    } else {
      const isExchange = operationNumbers?.length && nit;
      const recommendedPayload = getRecommendedRedirectPayload({ trip });
      transitionTo(`/purchase/${trip.id}/new/${passengers}`, {
        seenPrice: trip.pricing.total,
        exchangeData: isExchange
          ? JSON.stringify({
              operationNumbers,
              nit,
            })
          : '',
        ...(recommendedPayload && { tripsInfo: recommendedPayload }),
      });
    }
  }

  selectTripRedirect(trip) {
    const { roundTrip, setRedirectParams, passengersQueryParam, newPurchase } = this.props;
    const { redirectTo, departure, originId, destinationId, lineId, id } = trip;
    const { brand } = redirectTo;
    const selectedSeats =
      isSeatsOnResultActivated() &&
      getSeatsPurchasePayload({
        departureId: id,
      });

    const params = {
      departs_origin: originId,
      departs_destination: destinationId,
      departs_date: moment(departure).format('DD-MM-YYYY'),
      departs_hour: moment(departure).format('HH'),
      departs_minutes: moment(departure).format('mm'),
      departs_line: lineId,
      passengers: passengersQueryParam,
      selected_seats: selectedSeats && JSON.stringify(selectedSeats),
    };
    const shouldRedirectNow = !roundTrip;
    let waitForPurchase = false;

    if (roundTrip) {
      this.redirectRoundTrip(trip);
    } else {
      waitForPurchase = true;
      newPurchase(trip.id, trip.pricing.total);
    }
    setRedirectParams('departure', params, brand, shouldRedirectNow, roundTrip, waitForPurchase);
  }

  selectTrip(trip, position, isOpenTicket = false) {
    const { features } = this.props;
    const { redirectTo, recommendationType } = trip;
    if (recommendationType) {
      trackRecommendedTripSelected({ way: 'departure', recommendationType });
    }

    if (features.TRIP_REDIRECTION_LEAD && redirectTo) {
      this.selectTripRedirect(trip);
    } else {
      this.selectTripDefault(trip, position, isOpenTicket);
    }
  }

  selectOpenTripList() {
    const { passengers, transitionTo, origin, destination, departureDate, returnDate, roundTrip } =
      this.props;

    const route =
      `/search/${origin.id}/${destination.id}/${departureDate}` +
      `${roundTrip ? `/${returnDate}` : ''}` +
      `/p/${passengers}/departures/open`;

    transitionTo(route);
  }

  goToProviders() {
    const { currentPath, setTripFilter, transitionTo } = this.props;
    const route = currentPath.split('/');

    // Remove current provider id from currentPath array and add 'provider' to go
    // to return providers route
    route.pop();
    route.push('providers');

    // Reset location filters
    setTripFilter('departureLocation', 'none');
    setTripFilter('arrivalLocation', 'none');

    transitionTo(route.join('/'));
  }

  selectProvider(provider, ignoreHistory = false) {
    const {
      origin,
      destination,
      departureDate,
      returnDate,
      currentPath,
      setTripFilter,
      transitionTo,
      replaceUrl,
    } = this.props;
    let route = currentPath.split('/');

    pickedProviderTracker(
      {
        origin,
        destination,
        roundTrip: true,
        departureDate: moment(departureDate, 'DD-MMM-YY').toDate(),
        returnDate: moment(returnDate, 'DD-MMM-YY').toDate(),
      },
      provider,
    );

    // Remove current provider from currentPath array and add new provider id
    route.pop();
    route.push(provider.id);
    route = route.join('/');

    // Reset location filters
    setTripFilter('departureLocation', 'none');
    setTripFilter('arrivalLocation', 'none');

    if (ignoreHistory) {
      replaceUrl(route);
    } else {
      transitionTo(route);
    }
  }

  hasFiltersApplied() {
    const { filters } = this.props;
    return (
      !filters.departureTime.includes('none') ||
      filters.categories?.length ||
      !filters.stops.includes('none') ||
      filters.tripOptions?.length
    );
  }

  renderTripList(openTicket = null, isExchange = false) {
    const {
      isOpenTicketList,
      roundTrip,
      hasDynamicPromotion,
      isLogged,
      providerId,
      features,
      filters,
      tripsWithoutFilters,
      passengersNotFound,
      couponCode,
      discountedTripsByCoupon,
    } = this.props;

    const showOpenTicket =
      !this.hasFiltersApplied() || (this.hasFiltersApplied() && !tripsWithoutFilters.length);

    return isOpenTicketList ? (
      <OpenTicketList
        way="departure"
        roundTrip={roundTrip}
        selectTrip={this.selectTrip}
        providerId={providerId}
        isExchange={isExchange}
      />
    ) : (
      <>
        {features.OPEN_TICKET_MODAL_ENABLED && <ModalOpenTicket selectTrip={this.selectTrip} />}
        {features.SHOW_DYNAMIC_PROMOTIONS && hasDynamicPromotion && (
          <ModalDiscount goToAnticipationSearch={this.goToAnticipationSearch} />
        )}
        <Spacing responsiveSize="S" vertical>
          <RecommendedTripsList
            couponCode={couponCode}
            discountedTripsByCoupon={discountedTripsByCoupon}
            way="departure"
            selectTrip={this.selectTrip}
            onOptionClick={this.optionsClick}
            providerId={providerId}
            filtersApplied={filters}
          />
          <TripList
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
            way="departure"
            selectTrip={this.selectTrip}
            onOptionClick={this.optionsClick}
            tripOpenTicket={showOpenTicket && openTicket}
            isLogged={isLogged}
            filtersApplied={filters}
            showPoweredBy={!tripsWithoutFilters.length}
            showDotersBanner={!tripsWithoutFilters.length && !isLogged && features.DOTERS_ENABLED}
            passengersNotFound={passengersNotFound}
          />
        </Spacing>
      </>
    );
  }

  renderTripListWithoutFilters(openTicket = null) {
    const { tripsWithoutFilters, isLogged, filters, features } = this.props;
    return (
      <TripList
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        trips={tripsWithoutFilters}
        way="departure"
        selectTrip={this.selectTrip}
        onOptionClick={this.optionsClick}
        tripOpenTicket={openTicket}
        isLogged={isLogged}
        filtersApplied={filters}
        isNotFiltered
        showPoweredBy={Boolean(tripsWithoutFilters.length)}
        showDotersBanner={tripsWithoutFilters.length && !isLogged && features.DOTERS_ENABLED}
      />
    );
  }

  render() {
    const {
      provider,
      providers,
      noTrips,
      tooManyFilters,
      searchIsPolling,
      showModal,
      sortBy,
      sortProvidersBy,
      departureFilter,
      stopsFilter,
      categoriesFilter,
      tripOptionsFilter,
      departureLocationFilter,
      arrivalLocationFilter,
      locations,
      activeLocationFilter,
      activeDepartureFilter,
      activeStopsFilter,
      tripDetails,
      closeTripDetails,
      openTrip,
      roundTrip,
      trips,
      isStopsFilterAvailable,
      t,
      isOpenTicketList,
      nearTerminals,
      providerId,
      areOpenTicketTrips,
      features,
      departureDate,
      resetTripFilters,
      tripsWithoutFilters,
      operationNumbers,
      nit,
      goBack,
      discountPercent,
      growthBookFeatures,
      resultPriceToShow,
      firstTripLineId,
      firstTripLineLogo,
      brand,
      tripTerminalInfo,
    } = this.props;
    const hasResults = isOpenTicketList || !noTrips || (noTrips && tooManyFilters);
    const isExchange = operationNumbers?.length && nit;
    const departureMoment = getMomentFromSearchDateParam(departureDate);
    const departureDateText = `${departureMoment.format('DD')}-${departureMoment
      .format('MMMM')
      .slice(0, 3)}-${departureMoment.format('YY')}`;

    if (searchIsPolling && noTrips) {
      return <LoadingScreen resultMessages hasHeader={false} />;
    }

    const scheduleTitle = isOpenTicketList && 'openDeparture';
    const showNearbyTerminals =
      features.USE_NERBY_TERMINALS && nearTerminals?.length > 0 && !isExchange;

    let openTicketCTA = (
      <OpenTicketCTA
        openTrip={openTrip}
        way="departure"
        isRoundTrip={roundTrip}
        showWalletPoints={resultPriceToShow.priceType === 'wallet'}
        walletType={resultPriceToShow.walletType}
        onSelect={
          features.OPEN_TICKET_PROVIDER_SELECTION_ENABLED
            ? this.selectOpenTripList
            : this.selectTrip
        }
      />
    );

    if (isOpenTicketList && openTrip) {
      openTicketCTA = (
        <NormalTicketWrapper>
          <NormalTicketButton
            way="departure"
            onClick={isExchange ? goBack : this.goToProviders}
            isExchange={isExchange}
          />
        </NormalTicketWrapper>
      );
    }

    let TerminalsComponent;
    if (showNearbyTerminals) {
      TerminalsComponent = <NerbyTerminals onColumns />;
    }

    const showDaysControl = (!isOpenTicketList || areOpenTicketTrips) && !isExchange;

    const activeFiltersFunction = features.NEW_FILTERS_VERSION
      ? this.setActiveFilters
      : this.setActiveFilter;

    const lineOrDiscountBannerAreShown =
      features.SHOW_DISCOUNT_BANNER || features.EXTRA_RESULT_LINES?.includes(firstTripLineId);

    // TODO: REMOVE AB TEST
    const { newResultsDesign: isNewResultsDesign } = growthBookFeatures;

    const isSeatsOnResults = isSeatsOnResultActivated();

    let useSearchSidebar = features.SEARCH_SIDEBAR_ENABLED;
    if ((brand === 'berlinas' && isNewResultsDesign) || isSeatsOnResults) useSearchSidebar = false;

    return (
      <>
        {hasResults ? (
          <>
            {showNearbyTerminals && (
              <Visibility type="hideForMedium">
                <WhiteBar>{TerminalsComponent}</WhiteBar>
              </Visibility>
            )}

            <div
              className={`departures-container ${
                useSearchSidebar || isNewResultsDesign || !isSeatsOnResults ? 'with-sidebar' : ''
              } `}
              data-testid="search-results-container"
            >
              {!useSearchSidebar && (
                <>
                  <TripListHeader
                    way="departure"
                    provider={provider}
                    hasOtherProviders={providers.length > 1}
                    sortOn={!!sortBy}
                    departureFilterOn={activeDepartureFilter}
                    locationFilterOn={activeLocationFilter}
                    stopsFilterOn={activeStopsFilter}
                    onOptionClick={this.optionsClick}
                    showLocationFilter={
                      locations.departure.length > 2 || locations.arrival.length > 2
                    }
                    locations={locations}
                    floatingOptions={
                      2 + (locations.departure.length > 2 || locations.arrival.length > 2)
                    }
                    sortProperty={sortBy}
                    providerSortProperty={sortProvidersBy}
                    activeDeparture={departureFilter}
                    departureLocation={departureLocationFilter}
                    arrivalLocation={arrivalLocationFilter}
                    onSortSelect={this.setSortOrder}
                    onFilterSelect={activeFiltersFunction}
                    showModal={showModal}
                    onChangeProviderClick={this.goToProviders}
                    stopsFilter={stopsFilter}
                    trips={trips}
                    date={departureDateText}
                    isStopsFilterAvailable={isStopsFilterAvailable}
                    roundTrip={roundTrip}
                    isOpenTicketList={isOpenTicketList}
                    onResetFilters={resetTripFilters}
                  />
                  {isNewResultsDesign && !isExchange && (
                    <>
                      <Spacing
                        justifyContent={lineOrDiscountBannerAreShown ? 'space-between' : 'flex-end'}
                        size="S"
                      >
                        <When condition={features.EXTRA_RESULT_LINES?.includes(firstTripLineId)}>
                          <LineBanner brand={firstTripLineId} logoUrl={firstTripLineLogo} />
                        </When>
                        <When
                          condition={
                            features.SHOW_DISCOUNT_BANNER &&
                            !features.EXTRA_RESULT_LINES?.includes(firstTripLineId)
                          }
                        >
                          <DiscountBanner discountPercent={discountPercent} />
                        </When>
                        <SwitchDoters />
                      </Spacing>
                      <br />
                    </>
                  )}
                </>
              )}

              {useSearchSidebar && (
                <>
                  <div className="sidebar-title">
                    {features.USE_HORIZONTAL_RESULTS && (
                      <p className="sidebar-title-copy">{t('search:trip_discount_title')}</p>
                    )}
                  </div>
                  <SearchSidebar
                    onChangeSort={this.setSortOrder}
                    onChangeScheduleFilter={activeFiltersFunction}
                    scheduleFilter={departureFilter}
                    categoriesFilter={categoriesFilter}
                    tripOptionsFilter={tripOptionsFilter}
                    isStopsFilterAvailable={isStopsFilterAvailable}
                    stopsFilter={stopsFilter}
                    sort={sortBy}
                    way="departure"
                    hasOtherProviders={providers.length > 1}
                    onChangeProviderClick={this.goToProviders}
                    onResetFilters={resetTripFilters}
                    isOpenTicketList={isOpenTicketList}
                    isPolling={searchIsPolling}
                  />
                  <ResultsTitle
                    sectionTitle={scheduleTitle}
                    date={!isOpenTicketList && departureDateText}
                    rightContent={
                      isNewResultsDesign && !isExchange ? <SwitchDoters /> : openTicketCTA
                    }
                  />
                </>
              )}

              <div className="results-container">
                <Spacing vertical size="L">
                  {Boolean(searchIsPolling) && <LabelLoading />}
                  {hasResults || isOpenTicketList
                    ? this.renderTripList(openTicketCTA, isExchange)
                    : null}
                  {features.NEW_FILTERS_VERSION &&
                  tripsWithoutFilters.length &&
                  hasResults &&
                  !isOpenTicketList ? (
                    <>
                      <Text weight="bold" size="L" mobileSize="M">
                        {t('search:label.other_trips_you_may_be_interested_on')}
                      </Text>
                      {this.renderTripListWithoutFilters(openTicketCTA)}
                    </>
                  ) : null}

                  {tripTerminalInfo.hasCoords && (
                    <RouteTerminalsDisplay
                      terminalOrigin={tripTerminalInfo.originTerminal}
                      cityOrigin={tripTerminalInfo.originCity}
                      terminalDestination={tripTerminalInfo.destinationTerminal}
                      cityDestination={tripTerminalInfo.destinationCity}
                      originUrl={tripTerminalInfo.originMapUrl}
                      destinationUrl={tripTerminalInfo.destinationMapUrl}
                    />
                  )}

                  <Spacing vertical size="S">
                    <div className="results-bottom-container fade-in">
                      {Boolean(!searchIsPolling) && (
                        <Text size="S" color="grayLight">
                          <em>{t('general:showing_all_available_trips')}</em>
                        </Text>
                      )}
                    </div>

                    <div className="results-bottom-container fade-in">
                      {features.COUPON_ON_SEARCH && !isExchange && <Coupon />}

                      {!isOpenTicketList && features.PROVIDERS_SELECTION_ENABLED && (
                        <ChangeProvider
                          hasOtherProviders={providers.length > 1}
                          onChangeProviderClick={this.goToProviders}
                        />
                      )}
                    </div>
                  </Spacing>

                  {showNearbyTerminals && (
                    <Visibility type="hideForLargeOnly">{TerminalsComponent}</Visibility>
                  )}
                  {features.SHOW_MIN_PRICES && showDaysControl && !isExchange && (
                    <DayControls fixed way="departure" providerId={providerId} />
                  )}
                </Spacing>
              </div>
              <OverlayView
                title="trip_details"
                visible={Boolean(tripDetails)}
                hideOverlay={closeTripDetails}
              >
                <TripDetails details={tripDetails} />
              </OverlayView>
            </div>
          </>
        ) : (
          <NoResults way="departure" providerId={providerId} />
        )}
      </>
    );
  }
}

ProviderDepartures.propTypes = propTypes;

const mapStateToProps = (state) => ({
  features: state.whitelabelConfig.features,
});

export default connect(mapStateToProps)(
  withGrowthBookFeatures(ProviderDepartures, 'new_results_design', 'results_seats'),
);
